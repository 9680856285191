import type { ComponentType } from "react"

export function EnableRotation(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 180 }}
                transition={{
                    ease: "linear",
                    duration: 5,
                    repeat: Infinity,
                    repeatType: "loop",
                }}
            />
        )
    }
}
